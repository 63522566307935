import React, { useCallback, useEffect, useState } from 'react'
import MainLayout from 'components/MainLayout';
import NewEventModal from "components/NewEventModal";
import useEvents from "hooks/useEvents";
import { PlusIcon } from '@heroicons/react/20/solid';
import { EventsProvider } from "contexts/EventsContext";
import { Menu } from "@headlessui/react"
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline"
import { classNames } from "utils/misc"
import useRouter from 'hooks/useRouter';
import useSpinner from "hooks/useSpinner";

const Dashboard = ({ showEventModal = false }) => {
  const { navigate } = useRouter();

  const [isEventModalOpen, setIsEventModalOpen] = useState(showEventModal || false);
  const eventHooks = useEvents();
  const { events, fetchEvents, setName, loading } = eventHooks;
  const { renderSpinner } = useSpinner(loading);

  useEffect(() => {
    const getEvents = async () => {
      fetchEvents();
    };
    getEvents();
  }, [fetchEvents]);

  const onAddEventButtonClicked = useCallback(() => {
    setName("");
    setIsEventModalOpen(true);
    window.history.replaceState(window.history.state, "", `/events/new`);
  }, [setName]);

  const onCancelEventModal = useCallback(() => {
    setIsEventModalOpen(false);
    window.history.replaceState(window.history.state, "", `/`);
  }, []);

  const onSaveEventModal = useCallback(() => {
    fetchEvents();
    setIsEventModalOpen(false);
    window.history.replaceState(window.history.state, "", `/`);
  }, [fetchEvents]);

  return (
    <EventsProvider value={eventHooks}>
      <MainLayout>
        {/* Event Modal */}
        { isEventModalOpen &&
          <NewEventModal
            open={isEventModalOpen}
            onCancel={onCancelEventModal}
            onSaveEvent={onSaveEventModal} />
        }
        <div className='w-full max-w-700'>
          <div className="border-b border-gray-200 pb-5 flex flex-wrap items-center justify-between">
            <h1 id="primary-heading" className="font-bold text-3xl">
              Events
            </h1>
            { events.length > 0 &&
              <div className="sm:ml-4 sm:mt-0">
                <button
                  type="button"
                  onClick={onAddEventButtonClicked}
                  disabled={loading}
                  className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  <PlusIcon className="w-3 h-3 text-white mr-1" /> New event
                </button>
              </div>
            }
          </div>

          <div className="mt-4 flow-root">
            <div className="-my-2 -mx-4 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                { renderSpinner }
                <div className="relative">
                  { !loading && events.length === 0 &&
                    <div className="text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          vectorEffect="non-scaling-stroke"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                        />
                      </svg>
                      <h3 className="mt-2 text-sm font-semibold text-gray-900">No events</h3>
                      <p className="mt-1 text-sm text-gray-500">Get started by creating a new event.</p>
                      <div className="mt-6">
                        <button
                          type="button"
                          onClick={onAddEventButtonClicked}
                          className="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                        >
                          <PlusIcon className="w-3 h-3 text-white mr-1" /> New event
                        </button>
                      </div>
                    </div>
                  }
                  { !loading && events.length > 0 &&
                    <table className="min-w-full table-fixed divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th scope="col" className="min-w-[4rem] py-3.5 px-3 text-left text-sm font-semibold text-gray-900">
                            Name
                          </th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {events.map((event) => (
                          <tr key={event.id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                              <a href={`/events/${event.id}/people`}>
                                {event.name}
                              </a>
                            </td>
                            <td className="text-right pr-2">
                              <Menu as="div" className="relative inline-block text-left">
                                <div>
                                  <Menu.Button className="-m-2 flex items-center rounded-full p-2 text-gray-500 hover:text-gray-600">
                                    <span className="sr-only">Open options</span>
                                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a 
                                          href="#" 
                                          className={classNames(
                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                            'flex px-4 py-2 text-sm'
                                          )}
                                          onClick={() => navigate(`/event/edit/${event.id}`)}
                                        >
                                          Settings
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Menu>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </EventsProvider>
  )
};

export default Dashboard;
