import React, { Fragment, useCallback, useContext, useEffect, useState, useRef  } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/outline'

import useCustomer from 'hooks/useCustomer';
import SendMessageForDialog from './SendMessageForDialog';
import useCustomerMessages from 'hooks/useCustomerMessages';
import useRouter from "hooks/useRouter";
import moment from "moment";
import UserContext from "contexts/UserContext";
import MessageHistoryForDialog from './MessageHistoryForDialog';
import useCable from "hooks/useCable";

const PersonViewDialog = ({ open, setOpen, eventId, id }) => {
  const { customer, fetchCustomer } = useCustomer();
  const smsOptInDate = moment(customer.smsOptInDate);
  const smsOptOutDate = moment(customer.smsOptOutDate);
  const { fetchCustomerMessages, messages, MESSAGES_PER_PAGE, page, setPage, messagesTotal, loading, fetchMessage } = useCustomerMessages();
  const { navigate } = useRouter();
  const { setEventId, ecommerceExperienceFeatureFlag } = useContext(UserContext);
  useEffect(() => setEventId(eventId), [eventId, setEventId]);

  const slideOverRef = useRef(null);
  const { actionCableConsumer } = useCable();

  // Fetch customer data
  useEffect(() => {
    const initCustomer = async () => {
      await fetchCustomer(id);
    };
    initCustomer();
  }, [id, fetchCustomer]);
  // Fetch messages
  useEffect(() => {
    const fetchMessages = async () => {
      await fetchCustomerMessages(id);
    };
    fetchMessages();
  }, [id, fetchCustomerMessages]);
  const onMessageSendComplete = useCallback(async () => {
    await fetchCustomerMessages(id);
    setPage(1);
    
    if (slideOverRef.current) {
      slideOverRef.current.scrollTo({
        top: slideOverRef.current.scrollHeight,
        behavior: 'smooth'
      });
    }
  }, [id, fetchCustomerMessages, setPage]);
  const onNextPage = useCallback(() => {
    if (page < messagesTotal / MESSAGES_PER_PAGE) {
      setPage(page + 1);
    }
  }, [page, messagesTotal, MESSAGES_PER_PAGE, setPage]);
  const onEditPerson = useCallback(() => {
    navigate(`/events/${eventId}/person/edit/${id}`);
  }, [id, eventId, navigate]);

  useEffect(() => {
    const createCableSubscription = async () => {
      // Ecommerce orgs should not see message notifications
      if (ecommerceExperienceFeatureFlag) return;
      // Only show messages if there's a defined eventId
      if (!eventId) return;

      const consumer = await actionCableConsumer;

      consumer?.subscriptions?.create({
        channel: `MessagesChannel`,
        event_id: eventId
      }, {
        connected: () => console.log('Connected to action cable subscription on: ', `messages:${eventId}`),
        disconnected: () => console.log('Disconnected from action cable on channel: ', `messages:${eventId}`),
        received: (data) => onNewMessageReceived(data)
      });
    }

    createCableSubscription();

    return async () => {
      const consumer = await actionCableConsumer;
      console.log("Disconnecting from action cable");
      consumer.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCableConsumer, ecommerceExperienceFeatureFlag, eventId]);

  const onNewMessageReceived = useCallback(async (data) => {
    // Check if the customer id is the same of the message coming in
    if (parseInt(data.customer_id) === parseInt(id)) {
      await fetchMessage(data.id);
      // setPage(1);

      if (slideOverRef.current) {
        slideOverRef.current.scrollTo({
            top: slideOverRef.current.scrollHeight,
            behavior: 'smooth'
        });
      }

      // Do not show the message notification
      return true;
    }
    // If the message notification is from a different customer we show the notification
    return false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, fetchMessage, setPage]);

  const unsubscribed = customer.smsOptOutDate && smsOptOutDate > smsOptInDate;
  const customerPhone = customer.phone;  

  return (
    <Transition.Root appear="true" show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          appear="true"
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                appear="true"
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col justify-between pt-6 shadow-xl bg-[#fafafa] px-4 sm:px-6">
                    <div className="flex justify-between pb-4">
                      <div className='flex items-center'>
                        <h1 id="primary-heading" className="font-bold text-3xl">
                          {customer.fullName}
                        </h1>

                        <UserCircleIcon className="ml-4 w-6 h-6 cursor-pointer" onClick={() => navigate(`/events/${eventId}/person/${id}`)} />
                      </div>

                      <button
                        type="button"
                        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => setOpen(false)}
                      >
                        <span className="sr-only">Close panel</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>

                    {messages.length > 0 &&
                      <MessageHistoryForDialog messages={messages} page={page} invertOrder={true} loading={loading} perPage={MESSAGES_PER_PAGE} totalCount={messagesTotal} onNextPage={onNextPage} slideOverRef={slideOverRef} />
                    }
                    <SendMessageForDialog customerId={id} msgLoading={loading} messages={messages} unsubscribed={unsubscribed} customerPhone={customerPhone} editPerson={onEditPerson} onSendComplete={onMessageSendComplete} />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default PersonViewDialog;
