import React, { useCallback, useContext, useMemo, useState, useEffect } from "react";
import UserContext from "contexts/UserContext";
import { SEND_MESSAGE } from "utils/mutations";
import useRollbar from "hooks/useRollbar";
import useSmsBroadcast from "hooks/useSmsBroadcast";
import { useSnackbar } from "notistack";
import useApi from "hooks/useApi";
import AddLink from "components/AddLinkButton.js";
import NoPhoneEventTooltip from "components/NoPhoneEventTooltip";
import { PaperAirplaneIcon } from '@heroicons/react/24/outline';

const SendMessageForDialog = ({ customerId, unsubscribed, editPerson, customerPhone, msgLoading, onSendComplete }) => {
  const { captureError } = useRollbar();
  const { enqueueSnackbar } = useSnackbar();
  const { apolloClient } = useApi();

  const { eventId, hasEventPhone, ecommerceExperienceFeatureFlag } = useContext(UserContext);

  const { message, setMessage, link, setLink, addLink, handleModalOpen, handleModalClose, utmCampaign, setUtmCampaign, open } = useSmsBroadcast();

  const [loading, setLoading] = useState(false);

  const [messageSubject, setMessageSubject] = useState(null);

  const channel = ecommerceExperienceFeatureFlag ? "email" : "sms";

  const canSendMessage = useMemo(() => {
    if (!ecommerceExperienceFeatureFlag && !hasEventPhone) return;
    if (unsubscribed) return;
    if (!customerPhone) return;

    return message && message !== "";
  }, [unsubscribed, ecommerceExperienceFeatureFlag, hasEventPhone, message, customerPhone]);

  const sendMessage = useCallback(async () => {
    try {
      setLoading(true);
      await apolloClient.mutate({
        mutation: SEND_MESSAGE,
        variables: {
          eventId: parseInt(eventId),
          customerId: customerId,
          subject: messageSubject,
          body: message,
          channel: channel
        },
      });
      // enqueueSnackbar("Message was sent!", {
      //   autoHideDuration: 3000,
      //   variant: "success",
      // });
      setLoading(false);
      setMessageSubject("");
      setMessage("");
      onSendComplete();
    } catch (e) {
      enqueueSnackbar("Failed to send message:" + e.message, {
        autoHideDuration: 3000,
        variant: "error",
      });
      console.error(e);
      captureError(e);
      setLoading(false);
    }
  }, [eventId, messageSubject, message, setMessage, channel, customerId, onSendComplete, apolloClient, enqueueSnackbar, captureError]);

  const onMessageChange = useCallback((e) => {
    setMessage(e.target.value)
  }, [setMessage]);

  const onSubjectChange = useCallback((e) => {
    setMessageSubject(e.target.value)
  }, [setMessageSubject]);

  return (
    <div id="send-message" className="flex items-start py-5 mt-auto">
      <div className="min-w-0 flex-1">
        <div
            className="overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
          <label htmlFor="comment" className="sr-only">
            Write a message
          </label>
          { channel === "email" &&
            <input
            type="text"
            name="subject"
            id="subject"
            className="block w-full border-0 pt-2.5 text-lg font-medium placeholder:text-gray-400 focus:ring-0"
            placeholder="Subject"
            onChange={onSubjectChange}
            value={messageSubject}
            />
          }
          <textarea
            rows={3}
            name="comment"
            id="comment"
            autoFocus={false}
            className="block w-full resize-none border-0 py-3 focus:ring-0 sm:text-sm"
            placeholder="Write a message..."
            onChange={onMessageChange}
            value={message}
          />

          <div className="bg-white py-2 border-t border-gray-300" aria-hidden="true">
            <div className="py-px">
              <div className="h-9 flex justify-between mr-2">
                <div>
                  <AddLink
                    type="icon"
                    link={link}
                    setLinkState={setLink}
                    addLinkClickHandler={addLink}
                    handleModalOpen={handleModalOpen}
                    handleModalClose={handleModalClose}
                    modalStatus={open}
                    utmCampaign={utmCampaign}
                    setUtmCampaign={setUtmCampaign}
                  />
                </div>
                { !ecommerceExperienceFeatureFlag && (!hasEventPhone || unsubscribed || !customerPhone) &&
                  <NoPhoneEventTooltip elementId="#send-message-button"
                    eventPhone={hasEventPhone}
                    subscribed={!unsubscribed}
                    customerPhone={customerPhone}
                    editPerson={editPerson}
                    />
                }
                <button
                  id="send-message-button"
                  onClick={sendMessage}
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-25"
                  disabled={loading || !canSendMessage}
                >
                  { !loading &&
                    <>
                      <PaperAirplaneIcon className="h-4 w-4 mr-2"/>
                      <span>Send</span>
                    </>
                  }
                  { loading &&
                    <div className="flex justify-center items-center">
                      <svg className="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Sending...
                    </div>
                  }
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendMessageForDialog;
