import React, { useCallback, useContext, useEffect, useState } from "react";
import MobileSidebar from "components/MobileSidebar";
import NewTopMenu from "components/NewTopMenu";
import NoPhoneEventAlert from "components/NoPhoneEventAlert";
import NarrowSidebar from "components/NarrowSidebar";
import useCable from "hooks/useCable";
import UserContext from "contexts/UserContext";
import { useSnackbar } from "notistack";
const MainLayout = ({ pages, onNewMessageCallback, children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { actionCableConsumer } = useCable();
  const {
    eventId,
    ecommerceExperienceFeatureFlag,
    hasEventPhone
  } = useContext(UserContext);

  const onNewMessageReceived = useCallback(async (data) => {
    const callbackResult = onNewMessageCallback && await onNewMessageCallback(data);
    const lastSegment = window.location.pathname.split("/").pop();

    // If the message callback returns true we skip showing the message notification since the new message
    // will be appended to the current message history view.
    // Also if the current page is Inbox with Inbox tab we will skip the notification
    if (callbackResult === true && lastSegment && lastSegment === 'inbox') return;

    if (lastSegment && lastSegment != data.customer_id) { // Skip notification if it's on same person profile
      enqueueSnackbar(data.body, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        variant: 'messageNotification',
        messageId: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        customerId: data.customer_id
      });
    }
  }, [onNewMessageCallback, enqueueSnackbar]);

  useEffect(() => {
    const createCableSubscription = async () => {
      // Ecommerce orgs should not see message notifications
      if (ecommerceExperienceFeatureFlag) return;
      // Only show messages if there's a defined eventId
      if (!eventId) return;

      const consumer = await actionCableConsumer;

      consumer?.subscriptions?.create({
        channel: `MessagesChannel`,
        event_id: eventId
      }, {
        connected: () => console.log('Connected to action cable subscription on: ', `messages:${eventId}`),
        disconnected: () => console.log('Disconnected from action cable on channel: ', `messages:${eventId}`),
        received: (data) => onNewMessageReceived(data)
      });
    }

    createCableSubscription();

    return async () => {
      const consumer = await actionCableConsumer;
      console.log("Disconnecting from action cable");
      consumer.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionCableConsumer, ecommerceExperienceFeatureFlag, eventId]);

  return (
    <div className="flex">
      <NarrowSidebar />
      <MobileSidebar mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      <div className="flex flex-1 flex-col md:pl-[20rem]">
        { !ecommerceExperienceFeatureFlag && !hasEventPhone &&
          <NoPhoneEventAlert />
        }
        <NewTopMenu pages={pages} setMobileMenuOpen={setMobileMenuOpen} />
        <div className="flex flex-1 items-stretch">
          <main className="flex-1">
            <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last px-4 sm:px-8 pt-4 pb-8">
              { children }
            </section>
          </main>
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
